import React from 'react';
import PropTypes from "prop-types";

import useWindowDimensions, { deviceSize } from '../components/../common/utils';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'gatsby';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
  apiKey: process.env.BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React);

export const DeviceContext = React.createContext();

const DeviceProvider = props => {
    const { width } = useWindowDimensions();
    const isSmallScreen = React.useMemo(() => width < deviceSize.tablet.num, [width]);

    return (
        <DeviceContext.Provider value={isSmallScreen}>
            {props.children}
        </DeviceContext.Provider>
    );
};

DeviceProvider.propTypes = {
    children: PropTypes.array
}

const Element = ({ element }) => (
    <ErrorBoundary>
        <DeviceProvider>
            {element}
            <CookieConsent
                location="bottom"
                buttonText="OK"
                style={{ background: "rgba(255,255,255, 0.9)", color: 'rgba(0,0,0,0.60)', fontSize: "14px", boxShadow: '0px 0 1px rgba(0, 0, 0, 0.5)', fontFamily: 'Roboto' }}
                buttonStyle={{ color: "#4e503b", fontSize: "14px", borderRadius: '3px' }}
                expires={150}
                acceptOnScroll={true}
            >
                This website uses cookies to enhance the user experience. By using our site, you acknowledge that you have read and understand our <Link to="/cookie-policy">Cookie Policy</Link>.
            </CookieConsent>
        </DeviceProvider>
    </ErrorBoundary>
);

Element.propTypes = {
    element: PropTypes.element
};

export default Element;